<template>
  <div>
    <b-card v-if="me && me.type === 'admin'">
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="1"
          md="1"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Filtres:</label>

        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="12"
        >
          <b-row>
            <b-col
              cols="6"
              md="6"
              class="mt-2 mt-md-0"
            >
              <flat-pickr
                v-model="rangeDate"
                placeholder="Choisissez une date"
                class="form-control mr-1"
                :config="{ mode: 'range'}"
              />
            </b-col>
            <b-col
              cols="6"
              md="6"
              class="mt-2 mt-md-0"
            >
              <v-select
                v-model="assignee"
                :options="assigneeOptions"
                label="firstName"
                @input="setQuery"
              >
                <template #option="{ firstName, lastName }">
                  <span class="ml-50 d-inline-block align-middle"> {{ firstName }} {{ lastName }}</span>
                </template>
                <template #selected-option="{ firstName, lastName }">
                  <span class="ml-50 d-inline-block align-middle"> {{ firstName }}  {{ lastName }}</span>
                </template>
              </v-select>
            </b-col>
            <!-- <b-col
              cols="3"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label><u>Voir mes primes:</u></label>
              <b-form-checkbox
                v-model="showPrime"
                class="ml-2"
                name="is-rtl"
                switch
                inline
              />

            </b-col> -->
            <!-- <b-col
              cols="12"
              md="3"
              class="mt-2 mt-md-0"
            >
              <v-select
                v-model="boutique"
                placeholder="Boutique"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="boutiqueOptions"
                class="invoice-filter-select mr-1"
              />
            </b-col> -->
            <!-- <b-col
              cols="12"
              md="3"
              class="mt-2 mt-md-0"
            >
              <v-select
                v-model="shop"
                placeholder="Boutique"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="Boutique"
                :options="shopOptions"
                class="invoice-filter-select"
              />
            </b-col> -->
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="me && me.type === 'admin'">
      <b-row class="match-height">
        <b-col
          lg="3"
          md="3"
          cols="6"
        >
          <statistic-card-vertical
            color="success"
            icon="DollarIcon"
            :statistic="`${dashboard.ca || 0}€`"
            statistic-title="Chiffres d'affaires - Boutique"
          />
        </b-col>
        <b-col
          lg="3"
          md="3"
          cols="6"
        >
          <statistic-card-vertical
            color="primary"
            icon="DollarIcon"
            :statistic="`${dashboard.margeRetail || 0}€`"
            statistic-title="Marges - Boutique"
          />
        </b-col>
        <b-col
          v-if="dashboard.user && (dashboard.user.caOffline !== 0 || dashboard.user.margeOffline !== 0)"
          lg="3"
          md="3"
          cols="6"
        >
          <statistic-card-vertical
            color="secondary"
            icon="DollarIcon"
            :statistic="`${dashboard.totalOffline || 0}€`"
            statistic-title="Chiffres d'affaires - Offline"
          />
        </b-col>
        <b-col
          v-if="dashboard.user && (dashboard.user.caOffline !== 0 || dashboard.user.margeOffline !== 0)"
          lg="3"
          md="3"
          cols="6"
        >
          <statistic-card-vertical
            color="warning"
            icon="DollarIcon"
            :statistic="`${dashboard.margeOffline || 0}€`"
            statistic-title="Marges - Offline"
          />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col
          lg="2"
          md="2"
          cols="6"
        >
          <statistic-card-vertical
            color="success"
            icon="DollarIcon"
            :statistic="`${dashboard.totalDiscount || 0}€`"
            :statistic-title="`Remise client - (${(dashboard.totalDiscount / dashboard.ca * 100).toFixed(0)}%)`"
          />
        </b-col>
        <b-col
          lg="2"
          md="2"
          cols="6"
        >
          <statistic-card-vertical
            color="success"
            icon="DollarIcon"
            :statistic="`${dashboard.user ? dashboard.user.fees : dashboard.costRetail ? dashboard.costRetail : 0}€`"
            statistic-title="Coût fixe (Loyer/Charges/Salaires)"
          />
        </b-col>
        <b-col
          lg="2"
          md="2"
          cols="6"
        >
          <statistic-card-vertical
            color="success"
            icon="DollarIcon"
            :statistic="`${dashboard.fees || 0}€`"
            statistic-title="Acquisition & Support & Remboursement"
          />
        </b-col>
        <b-col
          lg="2"
          md="2"
          cols="6"
        >
          <statistic-card-vertical
            color="success"
            icon="DollarIcon"
            :statistic="`${dashboard.finalMargin || 0}€`"
            statistic-title="Marge finale"
          />
        </b-col>
        <b-col
          lg="2"
          md="2"
          cols="6"
        >
          <statistic-card-vertical
            color="success"
            icon="DollarIcon"
            :statistic="`${dashboard.primeRetail || 0}€`"
            statistic-title="Prime - Retail"
          />
        </b-col>
        <b-col
          v-if="dashboard.user && (dashboard.user.caOffline !== 0 || dashboard.user.margeOffline !== 0)"
          lg="2"
          md="2"
          cols="6"
        >
          <statistic-card-vertical
            color="success"
            icon="DollarIcon"
            :statistic="`${dashboard.primeOffline || 0}€`"
            statistic-title="Prime - Offline"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          lg="2"
          md="2"
          cols="6"
        >
          <statistic-card-vertical
            color="primary"
            icon="DollarIcon"
            :statistic="`${dashboard.mediumArticle || 0}`"
            statistic-title="Article par commande"
          />
        </b-col>
        <b-col
          lg="2"
          md="2"
          cols="6"
        >
          <statistic-card-vertical
            color="primary"
            icon="DollarIcon"
            :statistic="`${dashboard.mediumOrder.toFixed(0) || 0}€`"
            statistic-title="Panier moyen"
          />
        </b-col>
        <b-col
          lg="2"
          md="2"
          cols="6"
        >
          <statistic-card-vertical
            color="primary"
            icon="DollarIcon"
            :statistic="`${dashboard.orders.length || 0}`"
            :statistic-title="`Nombre de commande`"
          />
        </b-col>
        <b-col
          v-if="dashboard.source && dashboard.source.counter"
          lg="2"
          md="2"
          cols="6"
        >
          <statistic-card-vertical
            color="primary"
            icon="DollarIcon"
            :statistic="`${(dashboard.orders.length / (dashboard.source.counter || 0) *100).toFixed(0)}%`"
            :statistic-title="`Taux de conversion`"
          />
        </b-col>
        <b-col
          lg="2"
          md="2"
          cols="6"
        >
          <statistic-card-vertical
            v-if="dashboard.source && dashboard.source.counter"
            color="primary"
            icon="DollarIcon"
            :statistic="`${dashboard.source.counter}`"
            :statistic-title="`Passage boutique - ${dashboard.source.shop || ''}`"
          />
        </b-col>
      </b-row>
      <b-card>
        <b-row class="match-height">
          <b-col
            lg="12"
            md="12"
          >
            <b-table-lite
              :items="dashboard.orders.map(o => ({...o.order, ...o.order.shipping, ...o.cogs, seller:  o.seller ? o.seller.name : ''}))"
              :fields="['number', 'date_created', 'total', 'customer_note', 'nom', 'status', 'payment_method_title', 'seller']"
            >
              <template #cell(date_created)="data">
                <p>{{ $moment(data.item.date_created).locale('fr').format('DD MMMM YYYY') }}</p>
              </template>
              <template #cell(number)="data">
                <a :href="`/commande/${data.item.number}`" target="_blank">{{ data.item.number }}</a>
              </template>
              <template #cell(total)="data">
                <p>{{ data.item.total }}€
                  <br><span class="text-danger">{{ ((data.item.total / 1.2) - (data.item.cost + data.item.log)).toFixed(0) }}€ - Marge</span>
                  <br><span class="text-danger">{{ (( data.item.discount_total )) }}€ - Code</span>
                </p>
              </template>
              <template #cell(nom)="data">
                <p>{{ data.item.shipping.first_name }} {{ data.item.shipping.last_name }}</p>
              </template>
              <template #cell(status)="data">
                <b-badge
                  pill
                  :variant="`light-${resolveStatusVariant(data.item.status)}`"
                  class="text-capitalize"
                >
                  {{ data.item.status }}
                </b-badge>
              </template>
              <template #cell(seller)="data">
                <b-badge
                  pill
                  :variant="`light-warning`"
                  class="text-capitalize"
                >
                  {{ data.item.seller }}
                </b-badge>
              </template>
            </b-table-lite>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTableLite, BBadge,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTableLite,
    BBadge,
    // ApexDonutChart,
    // EcommerceMedal,
    // EcommerceStatistics,
    // EcommerceOrderChart,
    // EcommerceProfitChart,
    // EcommerceCompanyTable,
    StatisticCardVertical,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      data: {},
      dashboard: {},
      assigneeOptions: [],
      assignee: null,
      rangeDate: `${this.$moment().startOf('months').format('YYYY-MM-DD')} to ${this.$moment().format('YYYY-MM-DD')}`,
      shopOptions: [
        'TEMPLE',
        'LYON',
        'MIEU',
      ],
      showPrime: false,
      shop: 'TEMPLE',
    }
  },
  computed: {
    ...mapGetters('admin', ['me']),
  },
  watch: {
    rangeDate: {
      handler: 'setQuery',
      deep: true,
    },
    shop: {
      handler: 'setQuery',
      deep: true,
    },
  },
  async created() {
    const { users } = await this.$http.get('/user/list-retail-user')
    await this.get()
    this.assigneeOptions = [{ firstName: 'Chiffre', lastName: 'Global' }, ...users]
    this.assignee = { ...this.assigneeOptions[0] }
    await this.setQuery()
  },
  methods: {
    ...mapActions('admin', ['get']),
    async setQuery() {
      const filter = {}
      const $and = []
      // if (this.shop && this.shop !== '' && this.shop === 'LYON') {
      //   // $and.push({ 'system.shopName': this.shop })
      //   // $and.push({ 'system.shopName': this.shop })
      //   $and.push({ 'order.customer_note': { $in: ['LYON', 'LYON1', 'LYON2', 'LYON4', 'LYON3'] } })
      // } else if (this.shop && this.shop === 'MIEU') {
      //   $and.push({ 'order.customer_note': { $in: ['MIEU'] } })
      // } else {
      //   $and.push({ 'order.customer_note': { $in: ['TEMPLE', 'TEMPLE1', 'TEMPLE2', 'TEMPLE4', 'TEMPLE3'] } })
      // }
      console.log(this.rangeDate)
      if (this.rangeDate && this.rangeDate.includes('to') && this.rangeDate.split(' to ')?.length === 2) {
        const dates = this.rangeDate.split(' to ')
        $and.push({ created_at: { $gte: this.$moment(dates[0]).startOf('days').toDate().getTime(), $lte: this.$moment(dates[1]).endOf('days').toDate().getTime() } })
        if ($and?.length) {
          filter.$and = $and
          await this.getDashboard(filter)
        }
      }
    },
    async getDashboard(filter) {
      try {
        console.log('filter', this.assignee?.lastName)
        const urlQuery = this.assignee?.lastName === 'Global' ? '/admin/boutique/vendor-global' : '/admin/boutique/vendor'
        const { dashboard } = await this.$http.get(urlQuery, { params: { filter, assignee: this.assignee?._id } })
        this.dashboard = dashboard
      } catch (err) {
        console.log(err)
      }
    },
    resolveStatusVariant(status) {
      if (status === 'completed') return 'success'
      if (status === 'sended') return 'secondary'
      if (status === 'failed') return 'warning'
      if (['cancelled', 'on-hold'].includes(status)) return 'danger'
      return 'primary'
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
